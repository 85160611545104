/* eslint-disable @typescript-eslint/no-useless-constructor */
import { ProductLockRequestUseCaseResolver } from '@/v3/features/product-lock-request/domain/resolvers/product-lock-request-use-case.resolver';
/* eslint-disable-next-line */
import { catchError } from '@/v3/presentation/shared/error/index';
import { Injectable } from '@angular/core';
import { BasePresenter } from 'app/presentation/base/base.presenter';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { ProductLockRequestSideEffect } from './product-lock-request.side-effect';
import { ProductLockRequestViewEvent } from './product-lock-request.view-event';
import { ProductLockRequestViewState } from './product-lock-request.view-state';

@Injectable({ providedIn: 'root' })
export class ProductLockRequestPresenter extends BasePresenter<
  ProductLockRequestViewState,
  ProductLockRequestViewEvent,
  ProductLockRequestSideEffect
> {
  constructor(private _logMixpanelEventUseCase: LogMixpanelEventUseCase) {
    super();
  }

  protected defaultViewState(): ProductLockRequestViewState {
    return {
      requestList: [],
    };
  }

  onViewEvent(event: ProductLockRequestViewEvent): void {
    switch (event.type) {
      case 'Init': {
        this.fetchProductRequestList();
      }
    }
  }

  public fetchProductRequestList(): void {
    ProductLockRequestUseCaseResolver.productLockRequestsList()
      .then((result) => {
        this.viewState.requestList = result;
      })
      .catch((error) => {
        catchError(error);
      });
  }
}
